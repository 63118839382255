<template>
  <section class="section">
  <!-- <div class="fullScreen"> -->
    <div class="columns">
      <div class="column is-2" />
      <div class="column is-8">
        <p class="title is-5">Add Devices</p>
        <b-dropdown v-model="deviceFilter" aria-role="list">
          <button class="button is-info is-light" slot="trigger" slot-scope="{ active }">
            <span>{{ deviceFilter }}</span>
            <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
          </button>
          <b-dropdown-item v-for="(type, typeIdx) in deviceTypeList" :key="typeIdx" :value="type" aria-role="listitem">
            {{ type }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="column is-1 buttons">
        <b-button type="is-info" icon-right="plus" @click="showAddDevice = true;">
          <strong>Add Device</strong>  
        </b-button>
      </div>
      <div class="column is-1" />
    </div>
    <div class="columns">
      <div class="column is-2" />
      <div class="column is-8">
        <b-collapse
          class="card"
          animation="slide"
          v-for="(device, index) of displayDevices()"
          :key="index"
          :open="isOpen == index"
          @open="isOpen = index"
        >
          <div slot="trigger" slot-scope="props" class="card-header" role="button">
            <p class="card-header-title">
              <span class="has-text-info">{{ device.index }}._</span> {{ device.name }}
              <span v-if="device.guides" class="has-text-weight-light">: {{ device.guides.length }} guides</span>
              <span v-else class="has-text-weight-light">: no guides</span>
            </p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
            </a>
          </div>
          <div class="card-content">
            <div class="content has-text-left">
              <div class="columns">
                <div class="column">
                  <p class="title is-5">{{ device.name }}</p>
                </div>
                <div class="column is-3">
                  <b-button @click="isOpen = null;" class="is-danger is-light" icon-right="close">
                    <strong>Close</strong>
                  </b-button>
                </div>
                <div class="column">
                  <b-field>
                    <b-button @click="saveIndex(device.id)" class="control is-warning">
                      <strong>Save</strong>
                    </b-button>
                    <b-numberinput v-model="tempIndex" :placeholder="device.index" controls-alignment="right" controls-position="compact" type="is-warning" />
                  </b-field>
                  <b-field>
                    <b-button @click="saveTypeChange(device.id)" class="control is-warning">
                      <strong>Save</strong>
                    </b-button>
                    <b-input placeholder="e.g. iPhone, Galaxy" v-model="deviceToAddType" />
                  </b-field>
                </div>
              </div>
              <p class="subtitle mb-2">Guides:</p>
              <!-- <ul> -->
                <div>
                  <div class="buttons mb-1">
                    <b-button class="is-info" icon-right="plus" @click="openModal()">
                      <strong>Add</strong>
                    </b-button>
                    <b-button class="is-success" @click="saveToDB(device.id)">
                      <strong>Save to DB</strong>
                    </b-button>
                  </div>
                  <div v-for="(field, fieldIdx) in arrayToAdd" :key="fieldIdx" class="width-100 columns is-flex is-align-items-center">
                    <span class="column">
                      <strong>{{ fieldIdx+1 }}. </strong>{{ field.name }}
                    </span>
                    <span class="column is-3">
                      <strong>{{ field.guideID }}</strong>
                    </span>
                    <div class="column is-1">
                      <b-button @click="removeFromArray(fieldIdx)" class="is-danger" icon-right="delete" />
                    </div>
                  </div>
                </div>
                <li v-for="(guide, guideIdx) in device.guides" :key="guideIdx">
                  <strong>{{ guide.name }}</strong>
                  <p>{{ guide.guideID }}</p>
                </li>
              <!-- </ul> -->
            </div>
          </div>
        </b-collapse>
      </div>
      <div class="column is-2" />
    </div>

    <b-modal v-model="showModal">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <form @keyup.enter="addToArray()">
              <b-input placeholder="Link" v-model="link" />
              <b-input placeholder="Name" v-model="name" />
              <b-input placeholder="Guide ID" v-model="guideID" />
              <b-button class="is-info" type="submit" @click="addToArray()">Add Guide</b-button>
            </form>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="showAddDevice">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <form @keyup.enter="addDevice()">
              <b-input placeholder="Name" v-model="deviceToAdd" />
              <b-input placeholder="Index" v-model="indexToAdd" />
              <b-input placeholder="Type" v-model="deviceToAddType" />
              <b-button class="is-info" type="submit" @click="addDevice()">Add Device</b-button>
            </form>
          </div>
        </div>
      </div>
    </b-modal>

  </section>
</template>


<script>
import { mapGetters } from "vuex";
import { db } from '@/main';

export default {
  name: "addDevices",
  title: "Add Devices",
  data() {
    return {
      isOpen: null,
      arrayToAdd: [],
      showAddButton: true,
      showModal: false,
      link: '',
      name: null,
      guideID: null,
      tempIndex: null,
      showAddDevice: false,
      indexToAdd: null,
      deviceToAdd: null,
      deviceToAddType: null,
      deviceTypeList: [ 'All', 'iPhone', 'Samsung', ],
      deviceFilter: 'All',
    };
  },
  computed: {
    ...mapGetters({
      devices: "getDevicesList"
    })
  },
  methods: {
    displayDevices() { // filters the devices based on type
      if (this.deviceFilter === 'All') {
        return this.devices;
      } else {
        return this.devices.filter(item => {
          return item.type === this.deviceFilter;
        });
      }
    },
    openModal() {
      this.showModal = true;
    },
    addToArray() {
      if (!this.link) {
        window.alert('You must enter a link');
        return;
      }
      let array = this.link.split('/');
      // if we didn't type a name, autofill it
      let autoName = this.name;
      if (!autoName) {
        autoName = array[4].replaceAll('+', ' ');
      }
      // if we didn't type an id, autofill it
      let autoID = this.guideID;
      if (!autoID) {
        autoID = array.pop();
      }
      // add to array
      this.arrayToAdd.push({
        name: autoName,
        guideID: autoID,
      })
      // close everything
      this.name = null;
      this.guideID = null;
      this.link = null;
      this.showAddButton = true;
      this.showModal = false;
    },
    removeFromArray(idx) {
      // this.arrayToAdd.splice(idx, 1);
      this.$delete(this.arrayToAdd, idx)
    },
    saveIndex(doc) {
      let ref = db.collection('devices').doc(doc);
      ref.update({
        index: this.tempIndex
      })
      .then(() => {
        this.$buefy.toast.open('Changed index');
        this.arrayToAdd = [];
      })
      .catch(() => {
        this.$buefy.toast.open({
          message: 'Failed to change index',
          type: 'is-danger'
        })
      })
      // this.tempIndex = 0;
    },
    saveToDB(doc) {
      let ref = db.collection('devices').doc(doc);
      ref.get().then(snapshot => {
        if (snapshot.data().guides) {
          // console.log('exists')
          let guides = [];
          let combinedGuides = guides.concat(snapshot.data().guides, this.arrayToAdd)
          this.sendToFB(doc, combinedGuides)
        } else {
          // console.log('does not exist')
          this.sendToFB(doc, this.arrayToAdd);
        }
      })
    },
    sendToFB(doc, array) {
      let ref = db.collection('devices').doc(doc);
      ref.update({
        guides: array,
      })
      .then(() => {
        this.$buefy.toast.open('Added guides');
        this.arrayToAdd = [];
      })
      .catch(() => {
        this.$buefy.toast.open({
          message: 'Failed to add guides',
          type: 'is-danger'
        })
      })
    },
    addDevice() {
      db.collection('devices').add({
        name: this.deviceToAdd,
        index: Number(this.indexToAdd),
        type: this.deviceToAddType,
      })
      .then(() => {
        this.$buefy.toast.open('Added device');
        this.deviceToAdd = null;
        this.indexToAdd = null;
        this.deviceToAddType = null;
        this.showAddDevice = false;
      })
      .catch(() => {
        this.$buefy.toast.open({
          message: 'Failed to add device',
          type: 'is-danger'
        })
      })
    },
    saveTypeChange(doc) {
      let ref = db.collection('devices').doc(doc);
      if (this.deviceToAddType) { // change the type
        ref.update({
          type: this.deviceToAddType,
        }).then(() => {
          this.$buefy.toast.open('Changed type')
          this.deviceToAddType = null;
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.section {
  height: 90vh!important;
  max-height: 90vh!important;
  overflow: scroll!important;
}
</style>